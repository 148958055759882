import { LitElement, css, html } from 'lit';
import { customElement } from 'lit/decorators.js';
import { Router } from '@vaadin/router';

import '/src/pages/page-home';
import { GlobalStyles } from '@/css/global-styles.js';
@customElement('app-index')
export class AppIndex extends LitElement {
    static styles = [
      GlobalStyles,
      css`
        .copyrightSymbol {
          font-size: 1.3em;
          vertical-align: middle;
        }
        #routerOutlet {
            display: flex;
            flex-wrap: wrap;
        }

        #routerOutlet > * {
          width: 100% !important;
        }
        #routerOutlet > .leaving {
          animation: 160ms fadeOut ease-in-out;
        }
        #routerOutlet > .entering {
          animation: 160ms fadeIn linear;
        }
        @keyframes fadeOut {
          from {
            opacity: 1;
          }
          to {
            opacity: 0;
          }
        }
        @keyframes fadeIn {
          from {
            opacity: 0.2;
          }
          to {
            opacity: 1;
          }
        }
      `
    ]

    constructor() {
        super();
        this.querySelector('svg').remove();
    }

    firstUpdated() {
        // this method is a lifecycle even in lit
        // for more info check out the lit docs https://lit.dev/docs/components/lifecycle/

        // For more info on using the @vaadin/router check here https://vaadin.com/router
        const router = new Router(this.shadowRoot?.querySelector('#routerOutlet'));
        router.setRoutes([
            // temporarily cast to any because of a Type bug with the router
            {
                path: (import.meta as any).env.BASE_URL,
                animate: true,
                children: [
                  { path: '', component: 'page-home' },
                  {
                      path: '/qr/:id',
                      component: 'qr-index',
                      action: async () => {
                        await import('./pages/qr-index.js');
                      },
                  },{
                    path: '/location/:id',
                    component: 'location-index',
                    action: async () => {
                      await import('./pages/location-index.js');
                    },
                  },{
                    path: '(.*)',
                    component: 'page-not-found',
                    action: async () => {
                      await import('./pages/page-not-found.js');
                    },
                  }

                ]
            } as any,
        ]);
    }

    render() {
      return html`
        <div class="parent">
            <main>
                <div id="routerOutlet"></div>
            </main>
            <footer>
                <nav>
                    <span>${new Date().getFullYear() } <span class="copyrightSymbol">©</span> Schnauze Fabrik</span>
                </nav>

            </footer>
        </div>
    `;
    }
}