import { css } from 'lit';

export const GlobalStyles = css`
:host, html, html * {
    --primary-color: hsla(var(--primary-color-hue, 50), var(--primary-color-saturation, 100%), var(--primary-color-luminance, 50%), var(--primary-color-alpha, 1));
    --logo-secondary-fill: var(--primary-color);
    --highlight-color: rgba(239, 229, 169, var(--highlight-color-alpha, 1));
    --shadow-color-alpha: 1;
    --shadow-color: rgba(40, 40, 40, var(--shadow-color-alpha));
    --link-color: var(--shadow-color);
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'GT-Super-Display-Super', Rockwell, serif;
}

p {
    margin-bottom: 1.8em;
}

* {
    box-sizing: border-box;
}



.parent {
    min-height: 100vh;
    min-height: 100svh;
    max-width: 100%;
    display: grid;
    grid-template-rows: auto 1fr auto;
    margin: 0;
    padding:  1rem 0;
}

 nav {
    background-color: var(--primary-color);
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
}


a {
    color: var(--shadow-color);
}

nav a {
    height: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
    text-decoration: none;
}

 a,
 button {
    color: var(--link-color);
    font-weight: bolder;
    font-size: 1em;
    display: inline-block;
    padding: .5em .75em;
    background-color: var(--highlight-color);
    border: 2px solid var(--link-color);
    border-radius:  8px 0;
    box-shadow: 2px 2px 0px var(--link-color);
    margin: .5em 0;
    letter-spacing: .05em;
    text-decoration: none;
    transition: box-shadow .2s linear;
    max-width: 88%;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    opacity: 1;
    transform: scale(1);
    transition-property: opacity, transform;
    transition-duration: .4s;
    transition-timing-function: linear;
}

 a[disabled],
 button[disabled] {
    pointer-events: none;
    opacity: .5;
 }

a:hover,
a:focus,
button :hover,
button:focus{
    box-shadow: 5px 5px 0px var(--link-color);
}

a:active,
 button:active{
    box-shadow: 1px 1px 2px var(--link-color);
}

picture img, video {
    display: flex;
    margin: .5em 0 .8em;
    width: auto;
    height: auto;
    border: 5px solid var(--highlight-color);
    border-radius:  10px ;
    max-width: 100%;
}

video {
    background-color: var(--highlight-color);
}

[hidden] {
    opacity: 0;
    transform: scale(0);
    height: 0;
}


main {
    padding: 0 16px 16px 16px;
    max-width: 700px;
    margin: 0 auto 0;
    width: 100%;
    min-height:calc(100vh - (100px));
    min-height: calc(100svh - (100px));
}


footer {
    display: flex;
    --primary-color-hue: 50;
    --primary-color-saturation: 100%;
    --primary-color-luminance: 34%;
    --primary-color-alpha: 1;
    background-color: var(--primary-color);
    padding: 1rem ;
    align-items: center;
    justify-content: center;
}
`;
